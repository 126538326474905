import React from 'react';
import {Button, Icon} from 'antd';

const SectionSecond = props => {
    return (
        <div className='section' id='section2'>
            <h4 className='section2-h4'>Vážení obchodní priatelia</h4>
            <div>
                <div className='left pl'>
                    <img src={'/assets/images/vahy.jpg'} alt={'vahy'}/>
                </div>
                <p className='standard pl pr'><strong>Top Economic</strong> je firmou, ktorá síce vznikla až v roku 2005,
                    ale bola založená človekom, ktorý pôsobí v oblasti účtovníctva od roku 1993.
                    Špecializuje sa na vedenie účtovných agend pre malé a stredné podnikateľské subjekty, právnické a
                    fyzické osoby, podnikajúce v rôzných odvetviach obchodu a služieb. Rovnako sa špecializuje na
                    kompletné vedenie agendy súkromných lekárov.
                    Našim cieľom je vyhovieť požiadavkam a potrebám našich klientov, bez akejkoľvek výnimky.</p>
            </div>
            <div className='info'>
                <img id='team' src={'/assets/images/team.jpg'} alt={'team'}/><br/>
                <a href={'http://www.cus.sk/'} target='_parent'>Kurzy účtovníkov realizujeme v CUS<br/>
                <img id='cus' className='center' src={'/assets/images/kurzy-uctovnikov-cus.jpg'} alt={'CUS'}/></a>
            </div>
            <div className='info'>
                <Button.Group size='large'>
                    <Button onClick={() => props.fullpageApi.moveSectionUp()}>
                        <Icon type="up"/>Vyššie
                    </Button>
                    <Button onClick={() => props.fullpageApi.moveSectionDown()}>
                        Nižšie<Icon type="down"/>
                    </Button>
                </Button.Group>
            </div>
        </div>
    );
};

export default SectionSecond;
