import React from 'react';
import {Button} from 'antd';

const SectionThird = props => {
    return (
        <div className='section' id='section3'>
            <h5>IČO: <strong>40729079</strong></h5>
            <h5>DIČ: <strong>1039732870</strong></h5>
            <h5>IČDPH: <strong>SK1039732870</strong></h5>
            <h2>TopEconomic</h2>
            <h4>Pokroku 674, 981 01 Hnúšťa</h4>
            <h5>Prevádzka: <strong>Železničná 510, Hnúšťa</strong></h5>
            <h3>Telefón: 047 542 3530</h3>
            <h4>email: info@topeconomic.sk</h4>
            <div className='info'>
                <Button ghost shape="round"
                        icon="up"
                        onClick={() => props.fullpageApi.moveSectionUp()}
                        size='large'>Späť</Button>
            </div>
        </div>
    );
};

export default SectionThird;
